import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import axios, { AxiosError } from 'axios';
import { environment } from '../../environments/environment';

export const authLinkValidationGuard: CanActivateFn = async () => {
  const router = inject(Router);

  async function verifyLinkState() {
    const token = new URL(window.location.href).searchParams.get('token');

    if (!token) {
      return;
    }
    const currentUrl = window.location.pathname.includes('create-password')
      ? `${environment.apiBaseUrl}/auth-service/auth/verify/create-password-link`
      : `${environment.apiBaseUrl}/auth-service/auth/verify/reset-password-link`;

    try {
      return await axios.get(currentUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          const { message } = error.response.data;
          switch (message) {
            case 'Invalid Link':
              router.navigate(['/invalid-link']);
              break;
            case 'Link Expired':
              router.navigate(['/link-expired']);
              break;
            case 'Link Used':
              router.navigate(['/link-used']);
              break;
            default:
              break;
          }
        }
      }
      return;
    }
  }

  const response = await verifyLinkState();
  return response ? true : false;
};
