import { Routes } from '@angular/router';
import { testTakingPhaseGuard } from './services/TestTakingGuard/test-taking-phase.guard';
import { authLinkValidationGuard } from './services/auth-link-validation.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./authentication/login-page/login-page.component').then(
        (c) => c.LoginPageComponent
      ),
  },

  {
    path: 'login',
    loadComponent: () =>
      import('./authentication/login-page/login-page.component').then(
        (c) => c.LoginPageComponent
      ),
  },

  {
    path: 'change-password',
    loadComponent: () =>
      import('./authentication/change-password/change-password.component').then(
        (c) => c.ChangePasswordComponent
      ),
    canActivate: [authLinkValidationGuard],
  },
  {
    path: 'create-password',
    loadComponent: () =>
      import('./authentication/create-password/create-password.component').then(
        (c) => c.CreatePasswordComponent
      ),
    canActivate: [authLinkValidationGuard],
  },

  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./authentication/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },

  {
    path: 'reset-link-sent',
    loadComponent: () =>
      import('./authentication/reset-link-sent/reset-link-sent.component').then(
        (c) => c.ResetLinkSentComponent
      ),
  },

  {
    path: 'dashboard',
    loadChildren: () =>
      import('./main-app/main-app.routes').then((r) => r.mainAppRoutes),
  },
  {
    path: 'test-taker',
    loadChildren: () =>
      import('./test-taker/test-taker.routes').then((r) => r.testTakerRoutes),
  },

  {
    path: 'test-taking',
    loadChildren: () =>
      import('./test-taking/test-taking.routes').then(
        (r) => r.testTakingRoutes
      ),
    canActivate: [testTakingPhaseGuard],
  },

  {
    path: 'invalid-link',
    loadComponent: () =>
      import(
        './authentication/invalid-link-page/invalid-link-page.component'
      ).then((c) => c.InvalidLinkPageComponent),
  },

  {
    path: 'link-expired',
    loadComponent: () =>
      import(
        './authentication/link-expired-page/link-expired-page.component'
      ).then((c) => c.LinkExpiredPageComponent),
  },
  {
    path: 'link-used',
    loadComponent: () =>
      import('./authentication/link-used-page/link-used-page.component').then(
        (c) => c.LinkUsedPageComponent
      ),
  },

  {
    path: '**',
    loadComponent: () =>
      import('./not-found-page/not-found-page.component').then(
        (c) => c.NotFoundPageComponent
      ),
  },
];
