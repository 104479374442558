import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import { routes } from './app.routes';
import { loggerInterceptor } from './logger.interceptor';
import { errorInterceptor } from './error.interceptor';
import {
  provideCacheableAnimationLoader,
  provideLottieOptions,
} from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';

export const appProviders = [
  provideHttpClient(
    withInterceptors([loggerInterceptor, errorInterceptor]),
    withFetch()
  ),
  provideLottieOptions({
    player: () => player,
  }),
  provideCacheableAnimationLoader(),
  provideRouter(routes, withPreloading(PreloadAllModules)),
];
