import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CustomToastComponent } from './components/custom-toast/custom-toast.component';
import { ReloadService } from './services/reload.service';
import { CommonModule } from '@angular/common';
import { RefreshLoaderComponent } from './components/refresh-loader/refresh-loader.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CustomToastComponent,
    CommonModule,
    RefreshLoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  reloadService = inject(ReloadService);
}
