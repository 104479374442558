import { HttpEventType, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './services/auth.service';
import { tap } from 'rxjs';

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const tokenizedreq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authService.getToken()}`,
    },
  });

  return next(tokenizedreq).pipe(
    tap((event) => {
      if (event.type === HttpEventType.Response) {
        const accessToken = event.headers.getAll(
          'amap-auth-access-token'
        ) as string[];

        if (accessToken !== null && accessToken.length > 0) {
          sessionStorage.setItem('access_token', accessToken[0]);
          sessionStorage.setItem(
            'expires_at',
            event.headers.get('expires-at') as string
          );
        }
      }
    })
  );
};
