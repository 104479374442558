import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { ErrorMessage } from './services/authServiceInterfaces';
import { inject } from '@angular/core';
import { AuthService } from './services/auth.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  return next(req).pipe(
    catchError((error: HttpErrorResponse | ErrorMessage) => {
      if (error.status === 0) {
        error.error.message = 'Something went wrong, please try again later';
      }
      if (error.status === 502) {
        error.error.message =
          'Error communicating with Server. Please try again later';
      }
      if (error.status == 503) {
        error.error.message =
          error.error.data.message ||
          'The server is currently undergoing recovery. Please try again later.';
      }
      const newerror =
        error.error.message || error.error.error || error.error.error.message;

      if (error.status === 404) {
        throwError(() => newerror || 'file not found');
      }

      if (
        newerror.toLowerCase() === 'invalid token' ||
        newerror.toLowerCase() === 'expired token' ||
        newerror.toLowerCase() === 'missing token'
      ) {
        setTimeout(() => {
          authService.logOut();
        }, 1000);
      }

      return throwError(
        () =>
          newerror ||
          'Server is currently not available. Please try again later'
      );
    })
  );
};
